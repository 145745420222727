module.exports = {

    //Slot Machine
    SLOT_MACHINE: "slot-machine",
    PLAY_SLOT_MACHINE: "play-slot-machine",

    //Frolic Games
    FROLIC_GAMES: "frolic-games",
    PLAY_FROLIC_GAMES: "play-frolic-games"

    
}